import React from 'react';

import "react-notion/src/styles.css";
import "prismjs/themes/prism-tomorrow.css"; // only needed for code highlighting
import { NotionRenderer } from "react-notion";
import '../App.css';
import tosJson from "../assets/locationtos.json";

function LocationTOS() {
  return (
    <div className="App">
        {
          <NotionRenderer blockMap={tosJson} fullPage={true} />
        }
    </div>
  );
}

export default LocationTOS;
