import React from 'react';

import "react-notion/src/styles.css";
// import 'bootstrap/dist/css/bootstrap.min.css';
import "prismjs/themes/prism-tomorrow.css"; // only needed for code highlighting
import { NotionRenderer } from "react-notion";
import '../App.css';
import tosJson from "../assets/tos.json";

function TOS() {
  // const [response, setResponse] = useState("");

  // useEffect(() => {
  //   const NOTION_PAGE_ID = 'd78b43dfa85941bd88d626216bfb6f6d';
  //   fetch(`https://notion.postalpine.workers.dev/v1/page/${NOTION_PAGE_ID}`, {
  //     method: "GET",
  //     headers: {
  //       'Content-type': 'application/json'
  //     },
  //   }).then(
  //     (data) => {
  //       data.json().then(json => {
  //         console.log(json);
  //         setResponse(json);
  //       })
  //     });
  //   }, []);

  return (
    <div className="App">
        {
          <NotionRenderer blockMap={tosJson} fullPage={true} />
        }
    </div>
  );
}

export default TOS;
