import React from 'react';
// import React, { useEffect, useState } from 'react';

import "react-notion/src/styles.css";
// import 'bootstrap/dist/css/bootstrap.min.css';
import "prismjs/themes/prism-tomorrow.css"; // only needed for code highlighting
import { NotionRenderer } from "react-notion";
import '../App.css';
import privacyJson from "../assets/privacy.json";

function Privacy() {
  // const [response, setResponse] = useState("");

  // useEffect(() => {
  //   const NOTION_PAGE_ID = '94b462741c38455885202a98ce9f824e';
  //   fetch(`https://notion.postalpine.workers.dev/v1/page/${NOTION_PAGE_ID}`, {
  //     method: "GET",
  //     headers: {
  //       'Content-type': 'application/json'
  //     },
  //   }).then(
  //     (data) => {
  //       data.json().then(json => {
  //         console.log(json);
  //         setResponse(json);
  //       })
  //     });
  //   }, []);

  return (
    <div className="App">
        {
          <NotionRenderer blockMap={privacyJson} fullPage={true} />
        }
    </div>
  );
}

export default Privacy;
