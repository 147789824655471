import React, {useRef, useState, useEffect} from 'react';
import logo from "../assets/logo.png";
import home from "../assets/home.png";
import hosiptalSearch from "../assets/hospital-search.png";
import aosInstall from "../assets/aos-install.png";
import iosInstall from "../assets/ios-install.png";

import screen1 from "../assets/screen1.png";
import screen2 from "../assets/screen2.png";
import screen3 from "../assets/screen3.png";

import frame1 from "../assets/frame1.png";
import frame2 from "../assets/frame2.png";
import frame3 from "../assets/frame3.png";
import frame4 from "../assets/frame4.png";
import frame5 from "../assets/frame5.png";

import circleQuestion from "../assets/circle-question.png";
import recommIcon from '../assets/hospital-recomm/recomm-icon.png';
import searchIcon from '../assets/hospital-recomm/search-icon.svg';

const downloadIOS = () => {
  window.location = "https://apps.apple.com/us/app/%EB%91%90%EB%8F%8C-5%EC%B4%88-%EC%95%88%EC%97%90-%EB%B0%9B%EC%95%84%EB%B3%B4%EB%8A%94-%EC%86%8C%EC%95%84%EA%B3%BC-%EC%A0%84%EB%AC%B8%EC%9D%98-%EC%A1%B0%EC%96%B8/id6480494993";
};

const downloadAOS = () => {
  window.location = "https://play.google.com/store/apps/details?id=com.postalpine.doodol";
};

const downloadApp = () => {
  if (
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i)
  ) {
    downloadIOS();
  } else {
    downloadAOS();
  }
};

const sample1 = [
  '약시', '소아정신과', '고열', '콧물', '내사시', '알러지', '발달지연', '외사시', '초고도원시',
  '척추측만증', '사시', '근시', '과잉치', '정형외과 문제', '결막염', '호흡기 문제',
  '소아 비염, 축농증', '소아안과', '상사시,복합사시,고도원시,고도근시,약시', '언어지연',
  '소아 아데노이드 비대', '경직', '난시, 원시', '손가락 골절 의심', '신경치료',
  '고도 원시, 난시, 약시, 사시', '유피종', '척추', '발달', '소아재활', '예방접종', '심장 질환',
  '소아 열', '발달 지연', '복통', '고관절 활액막염', '난시, 약시', '간혈관종', '재활치료',
  '안짱다리', '폐렴', '피부질환', '소아족저피부염', '천식', '중이염', '간헐외사시', '간헐적 외사시',
  '소아사시', '고관절 이형성증', '장중첩증', '소아응급', '소아 치과', '충치', '어지럼증', '팔골절',
  '열경기', '경련, 연축', '정형외과', '평발', '흉부 통증', '발목 골절', '고도원시', '자폐',
  '소아신경과', '화상', '열감기', '소아외과', '심장잡음', '난시', '후유장해 진단', '설사', '사경',
  '성조숙증', '간헐적외사시', '재활의학과', '소아 알레르기성 결막염', '뇌전증', '자폐스펙트럼', '부정맥',
  '요로감염'
];
const sample2 = [
  '서혜부탈장', '장염', '심장 문제', '아토피', '소아 낮병동', '알레르기접촉성피부염', '고관절탈구',
  '소아 정형외과', '염증', '척추측만', '대근육 발달 문제', '피검사', '배 통증', '복시',
  'BCG 예방접종', '복부초음파', '약시, 사시', '눈물고임', '재활', '소아정형외과', '간헐성 외사시',
  '입술 처짐', '물사마귀', '시력 저하', '매복치아', '틱', '열', '변비', '빈뇨', '상처',
  '야뇨증', '두드러기', '다리 통증', '속눈썹 찌름', '언어치료', '발달 장애', '편도 아데노이드',
  '팔꿈치 골절', '손가락 뼈 문제', '사경,사두증', '뇌 검사', '상사시', '시력저하', '기타 안질환',
  '고관절 문제', '소아 탈장', '심장 두근거림', '소아암', 'SMA', '다리 절뚝거림', '백질연하증',
  '독감', '무릎 통증', '원시', '간헐성 사시', '어깨인대파열', '발 통증', '소아응급실', '소아 질환',
  '산부인과 질환', '영유아검진', '비염', '근시, 난시', '유문협착증', '갑상선 기능저하증',
  '선천성 백내장', '고도근시', '소아 두통', '사두', '눈물길 막힘', '안과검사', '안검하수', '다래끼',
  '콩다래끼', '소아성대결절', '고도 원시', '기관지염, 폐렴', '탈장', '눈 질환', '소아천식'
]


function Mobile () {
  const scrollRef = useRef(null);
  const [isDrag, setIsDrag] = useState(false);
  const [startX, setStartX] = useState();
  const [threshold, setThreshold] = useState(1);
  const [keywords1, setKeywords1] = useState(sample1);
  const [keywords2, setKeywords2] = useState(sample2);

  const throttle = (func, ms) => {
    let throttled = false;
    return (...args) => {
      if (!throttled) {
        throttled = true;
        setTimeout(() => {
          func(...args);
          throttled = false;
        }, ms);
      }
    };
  };

  const onDragStart = (e) => {
    e.preventDefault();
    setIsDrag(true);
    setStartX(e.pageX + scrollRef.current.scrollLeft);
  };

  const onDragEnd = () => {
    setIsDrag(false);
  };

  const onDragMove = (e) => {
    if (isDrag) {
      const { scrollWidth, clientWidth, scrollLeft } = scrollRef.current;
  
      scrollRef.current.scrollLeft = startX - e.pageX;
  
      if (scrollLeft === 0) {
        setStartX(e.pageX);
      } else if (scrollWidth <= clientWidth + scrollLeft) {
        setStartX(e.pageX + scrollLeft);
      }
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      scrollRef.current.scrollLeft += 1.0;
      if (scrollRef.current.scrollLeft > 2000 * threshold) {
        setKeywords1(prevArr => [...prevArr, ...sample1]);
        setKeywords2(prevArr => [...prevArr, ...sample2]);
        setThreshold(thr => thr + 1);
      }
    }, 50)
    return () => {
      clearInterval(interval)
    }
  }, [threshold]);

  const delay = 100;
  const onThrottleDragMove = throttle(onDragMove, delay);

  const onClickKeyword = (keyword) => () => {
    console.log(keyword);
    alert("소아 진료 병원 & 교수님 추천은 두돌 앱 다운로드 이후 가능해요!");
    downloadApp();
  }

  return (
    <div className="justify-center text-center bg-white scrollbar-hide">
      <nav className="justify-center h-[52px] py-[14px]">
        <img src={logo} alt="header" className="w-[104px] m-auto" />
      </nav>

      <div className="bg-[#DEF8E0] overflow-hidden">
        <div className="h-[62px]"></div>
        <div className="text-[30px] text-[#1F1F1F] tracking-[-2.5%] leading-[40px] font-[600] pretendard">
          초보 부모를 위한 AI<br/>
          육아 검색 엔진 두돌
        </div>

        <div className="h-[16px]"></div>

        <div className="text-[16px] text-[#747474] tracking-[-2.5%] leading-[24px] font-[400] pretendard">
          의료 상담, 병원 추천, 용품 후기<br/>
          무엇이든 물어보세요!
        </div>

        <div className="h-[24px]"></div>

        <div className="inline-flex">
          <img onClick={downloadAOS} src={aosInstall} alt="small-logo" className="w-[94px] m-auto mr-[8px]"/>
          <img onClick={downloadIOS} src={iosInstall} alt="small-logo" className="w-[94px] m-auto"/>
        </div>

        <div className="h-[36px]"></div>

        <img src={home} alt="home" className="w-[244px] m-auto drop-shadow-xl"/>
      </div>

      <div className="text-[#44DA63] text-[24px] font-[600] leading-[34px] tracking-[-0.6px] mb-[16px] mt-[72px]">
        소아 진료 잘보는<br/>교수님&병원 어디?
      </div>

      <div className="text-[14px] text-[#505050] leading-[22px] tracking-[-0.35px]">
        광고 없는 진짜 후기 보고, <br/>
        소중한 우리 아기에게 맞는 병원을 찾아보세요!
      </div>

      <div className="h-[39px]"></div>

      <div className="w-full px-[20px] max-w-[430px]" onClick={() => {
        alert("소아 진료 병원 & 교수님 추천은 두돌 앱 다운로드 이후 가능해요!");
        downloadApp();
      }}>
        <div className="border-[1px] border-[#44DA63] rounded-[10px] pl-[16px] pr-[16px] py-[13px] text-left flex justify-between">
          <input className="text-[15px] text-[#333] leading-[22px] tracking-[-0.375px] font-[400] min-w-[85%] focus:outline-none"
            placeholder="궁금한 질병을 검색해 보세요"
          />
          <img
            src={searchIcon}
          />
        </div>
      </div>

      <div className="h-[45px]">

      </div>

      <div className="text-[16px] font-[600] text-[#1F1F1F] leading-[24px] inline-flex">
        <img src={recommIcon} className="w-[26px] h-[26px] mr-[8px]"/>
        다양한 질병을 검색해보세요
      </div>

      <div className="h-[25px]"></div>

      <div className="scrollDiv overflow-x-scroll" ref={scrollRef}
        onMouseDown={onDragStart}
        onMouseMove={onThrottleDragMove}
        onMouseUp={onDragEnd}
        onMouseLeave={onDragEnd}
      >
        <div className="px-[20px] flex">
          {
          keywords1.map((keyword, idx) => {
            return (
              <div key={`keyword1_${idx}`} className="flex-none text-[#505050] px-[16px] py-[9px] text-[13px] leading-[18px] rounded-[18px] border-[1px] border-[#EAEAEA] mr-[8px]" onClick={onClickKeyword(keyword)}>
                {keyword}
              </div>
            )
          })
          }
        </div>
        <div className="flex mt-[8px] ml-[-20px]">
          {
          keywords2.map((keyword, idx) => {
            return (
              <div key={`keyword1_${idx}`} className="flex-none text-[#505050] px-[16px] py-[9px] text-[13px] leading-[18px] rounded-[18px] border-[1px] border-[#EAEAEA] mr-[8px]" onClick={onClickKeyword(keyword)}>
                {keyword}
              </div>
            )
          })
          }
        </div>
      </div>

      <div className="h-[72px]"></div>

      <div className="text-[13px] text-[#747474] tracking-[-2.5%] leading-[18px] font-[400] pretendard">
        간단한 검색만으로 믿을 수 있는 <br/>
        소아과 의사의 지침을 답변으로 제공해드려요!
      </div>

      <div className="h-[8px]"></div>

      <div className="text-[20px] text-[#333333] tracking-[-2.5%] leading-[28px] font-[600] pretendard">
        육아 궁금증 5초만에 해결
      </div>

      <div className="h-[36px]"></div>

      <div className="inline-flex pl-[60px] overflow-x-auto scrollbar-hide pb-[110px]">
        <img src={screen1} className="w-[219px] h-[474px] mr-[40px] drop-shadow-xl"/>
        <img src={screen2} className="w-[219px] mr-[40px] drop-shadow-xl"/>
        <img src={screen3} className="w-[219px] mr-[40px] drop-shadow-xl"/>
      </div>

      {/* <div className="h-[36px]"></div> */}

      <div className="text-[15px] text-[#505050] tracking-[-2.5%] leading-[22px] font-[400] pretendard">
        육아 중 궁금한 모든 것들을 검색해보세요!
      </div>

      <div className="h-[24px]"></div>

      <div className="pl-[12%]">
        <img src={frame1} className="w-[167px]"/>
      </div>
      <div className="h-[16px]"></div>
      <div className="pr-[12%]">
        <img src={frame2} className="w-[223px] ml-auto"/>
      </div>
      <div className="h-[16px]"></div>
      <div className="pl-[12%]">
        <img src={frame3} className="w-[220px]"/>
      </div>
      <div className="h-[16px]"></div>
      <div className="pr-[12%]">
        <img src={frame4} className="w-[177px] ml-auto"/>
      </div>
      <div className="h-[16px]"></div>
      <div className="pl-[12%]">
        <img src={frame5} className="w-[182px]"/>
      </div>

      <div className="h-[72px]"></div>

      <div className="text-[13px] text-[#747474] tracking-[-2.5%] leading-[18px] font-[400] pretendard">
        늦은 밤 아이가 아파 걱정이신가요?
      </div>

      <div className="h-[8px]"></div>

      <div className="text-[20px] text-[#333333] tracking-[-2.5%] leading-[28px] font-[600] pretendard">
        두돌에서 휴일/야간에 진료가 가능한<br/>
        소아 전문 병원을 찾아보세요!
      </div>

      <div className="h-[36px]"></div>

      <img src={hosiptalSearch} alt="hospital-search" className="w-[244px] m-auto drop-shadow-xl"/>

      <div className="h-[72px]"></div>

      <div className="text-[18px] text-[#1F1F1F] tracking-[-2.5%] leading-[28px] font-[600] pretendard">
        무엇이든 물어보세요 두돌이 5초 안에 <br/>
        육아 궁금증을 해결해드려요
      </div>

      <div className="h-[36px]"></div>

      <div className="inline-flex">
        <img onClick={downloadAOS} src={aosInstall} alt="small-logo" className="w-[94px] m-auto mr-[8px]"/>
        <img onClick={downloadIOS} src={iosInstall} alt="small-logo" className="w-[94px] m-auto"/>
      </div>

      <div className="h-[72px]"></div>

      <div className="px-[10%] h-fit">
        <div className="mb-[32px] h-[1px] bg-gehagray-20"></div>

        <a href="http://pf.kakao.com/_DxjpWG/chat">
          <div className="inline-block w-fit flex py-[9.5px] px-[12px] bg-[#FFFFFF] border-[1px] border-solid border-[#EAEAEA] text-[#1F1F1F] text-[14px] leading-[24px] tracking-[-0.5%] rounded-[8px] align-middle">
            <img
              src={circleQuestion}
              alt=""
              className="mr-[4px] w-[24px] h-[24px] my-auto"
            />
            고객센터
          </div>
        </a>
        <div className="mt-[18px] text-[12px] leading-[150%] tracking-[-0.5px] text-[#959595] text-left">
          고객센터 운영시간 : 10:00 ~ 19:00 (주말, 공휴일 제외)
        </div>

        <div className="flex pt-[18px] text-[#959595]">
          <a href="https://doodol.ai/policy/ko/tos">
            <div className="font-[600] text-[13px] tracking-[-0.5px] leading-[150%]">
              서비스 이용 약관
            </div>
          </a>

          <a href="https://doodol.ai/policy/ko/privacy">
            <div className="ml-[24px] font-[600] text-[13px] tracking-[-0.5px] leading-[150%]">
              개인정보처리방침
            </div>
          </a>
        </div>

        <div className="mb-[48px] text-left">
          <div className="mt-[18px] whitespace-pre-wrap text-[12px] tracking-[-0.5px] leading-[18px] text-[#959595]">
            {/* (주) 포스트알파인  |  사업자등록번호 : 518-86-01993  |  대표이사 : 김지현<br/> */}
            (주) 포스트알파인 | 사업자등록번호 : 518-86-01993 | 대표자 : 김지현<br />
            주소 : 서울특별시 관악구 낙성대로 2 4층 404호 <br />
            전화번호 : 02-2088-4661 <br />
            <br />© Postalpine Inc. All rights reserved.
          </div>
        </div>
      </div>

      {/* <div className="h-[170px]"></div> */}

    </div>
  )
}

export default Mobile;