import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import gpsIcon from "../../assets/emergency/gps.png";
import locationIcon from "../../assets/emergency/location.png";
import stroke from "../../assets/emergency/stroke.png";
import xIcon from "../../assets/emergency/x.png";
import vectorIcon from "../../assets/emergency/vector.png";
import editIcon from "../../assets/emergency/edit.png";
import Loader from "react-spinners/SyncLoader";
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
// import axios from 'axios';

const colorMap = {
  2: {
    bg: "rgba(64, 202, 20, 0.10)",
    text: "#3FCA13"
  },
  4: {
    bg: "rgba(217, 194, 0, 0.10)",
    text: "#D9C200"
  },
  6: {
    bg: "rgba(255, 184, 0, 0.10)",
    text: "#FFB900"
  },
  8: {
    bg: "rgba(251, 87, 94, 0.10)",
    text: "#FB575E"
  }
}

function NoGeoLocationHome (props) {
  return (
    props.loading ? 
    <div className="justify-center w-full min-h-[100vh] text-center align-middle pt-[46vh]">
      <Loader
        color="#44DA63"
        loading={props.loading}
        size={15}
      />
    </div> :
    <div className="justify-center text-center bg-[#FFFFFF] overflow-x-hidden scrollbar-hide">
      <div className="h-[265px]">

      </div>
      <div className="text-[14px] text-[#1F1F1F] leading-[20px] tracking-[-0.35px] pretendard">
        진료 가능한 야간 진료 병원의 정확한<br/>
        정보 안내를 위해 위치 사용을 허용해 주세요!
      </div>

      <div className="h-[24px]"></div>

      <button
        className="text-[14px] text-[#111111] leading-[16px] tracking-[0.325px] pretendard px-[16px] py-[9px] border-[1px] rounded-[100px] outline-[#EAEAEA] justify-center flex m-auto"
        onClick={() => {
          props.setLoading(true);
          props.getCurrentPosition();
        }}
      >
        <img src={gpsIcon} className="w-[16px] h-[16px] mr-[4px] leading-[18px]"/>
        위치 사용 허용하기
      </button>
    </div>
    
  )
}
NoGeoLocationHome.propTypes = {
  getCurrentPosition: PropTypes.func,
  loading: PropTypes.bool,
  setLoading: PropTypes.func
}


function GeoLocationHome (props) {
  useEffect(() => {}, []);
  const [display, setDisplay] = useState('')
  const onClickDialog = () => {
    setDisplay('none');
  }
  const onClickQuestion = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage("question");
    }
  }

  return (
    <div className="justify-center text-center bg-[#FFFFFF] overflow-x-hidden scrollbar-hide px-[20px]">
      <div className="h-[24px]">

      </div>
      
      <div className="w-full [contain:content]">
        <div
          className="text-[13px] text-[#111111] leading-[16px] tracking-[0.325px] pretendard px-[16px] py-[9px] border-[1px] rounded-[100px] outline-[#EAEAEA] justify-center flex m-auto w-fit float-left"
          onClick={props.getCurrentPosition}
        >
          <img src={gpsIcon} className="w-[16px] h-[16px] mr-[4px] leading-[18px]"/>
          {props.areaName}
        </div>
        <div
          className="text-[13px] text-[#111111] leading-[16px] tracking-[0.325px] pretendard px-[16px] py-[9px] border-[1px] rounded-[100px] outline-[#EAEAEA] justify-center flex m-auto w-fit ml-[8px] float-left"
        >
          거리순
        </div>
      </div>

      <div className="h-[24px]"></div>

      <div className="text-[#1F1F1F] text-[15px] font-[600] leading-[22px] tracking-[-0.375px] text-left">
        지금 방문 가능한 소아과 병원을 <br/>
        실시간으로 알려드려요!
      </div>

      <div className="h-[24px]"></div>

      <div>
        {props.hospitals.map((hospital, idx) => {
          return (
            <a key={'a_' + idx} href={"/emergency/" + hospital.id}>
              <div key={idx} className="bg-[#F9F9F9] px-[24px] py-[24px] mb-[12px] text-left rounded-[8px]">
                <div className="text-[#747474] text-[12px] leading-[18px] font-[600] tracking-[-0.3px] ">
                  {hospital.kind}
                </div>
                <div className="text-[#333] text-[16px] font-[600] leading-[24px] tracking-[-0.4px] py-[8px] max-w-[269px]">
                  {hospital.name}
                </div>
                <div className="flex text-[#747474] text-[13px] leading-[18px] tracking-[-0.325px]">
                  <img src={locationIcon} className="w-[14px] h-[14px] mr-[4px] leading-[18px] mt-[2px]"/>
                  {hospital.distance > 1000 ? (hospital.distance/1000).toFixed(1) + "km" : hospital.distance.toFixed(1) + "m"}
                  <img src={stroke} className="w-[1px] h-[12px] mt-[3px] mx-[8px] leading-[18px]"/>
                  {
                    hospital.endsAt == '24:00:00' && hospital.startsAt == '00:00:00' ?
                    "24시간 진료" : hospital.endsAt.substr(0, 2) + "시 진료 마감"
                  }
                </div>
                {
                hospital.waitLevel != null || hospital.sickbed != null ?
                  <div className="h-[24px]">
                  </div> : null
                }
                

                <div className="flex">
                  {/* {
                  hospital.waitLevel > 0 ? 
                    <div className="rounded-[4px] px-[6px] py-[2px] tracking-[-0.3px] font-[500] leading-[18px] text-[12px] w-fit mr-[8px]" style={{
                      backgroundColor: colorMap[hospital.waitLevel * 2].bg,
                      color: colorMap[hospital.waitLevel * 2].text
                      }}>
                        {hospital.waitLevel * 2}시간 대기 예상
                    </div> : null
                  } */}
                  {
                   hospital.sickbed != null ? 
                    <div className="rounded-[4px] px-[6px] py-[2px] tracking-[-0.3px] font-[500] leading-[18px] text-[12px] w-fit" style={{
                      backgroundColor: colorMap[Math.max(2 * (4 - parseInt((Math.max(hospital.sickbed.leftOver, 0)+1) / 2)), 2)].bg,
                      color: colorMap[Math.max(2 * (4 - parseInt((Math.max(hospital.sickbed.leftOver, 0)+1) / 2)), 2)].text
                      }}>
                        잔여 병상 {hospital.sickbed.leftOver}자리
                    </div> : null
                  }
                </div>
                
              </div>
            </a>
          )
        })}
      </div>

      <div className="fixed bottom-[24px] w-full pr-[40px] flex justify-end">
        <div className="py-[6px] px-[12px] bg-[#505050] text-white rounded-[8px] font-[400] leading-[18px] tracking-[-0.325px] text-[13px] flex text-left self-center" onClick={onClickDialog} style={{display: display}}>
          진료 대기가 너무 길어지나요? <br/>
          두돌로 먼저 조언 받아보세요
          <img src={xIcon} className="w-[12px] h-[12px] ml-[8px] self-center"/>
        </div>
        <img src={vectorIcon} className="w-[6px] h-[10px] self-center ml-[-1px] mr-[4px]" style={{display: display}}/>

        <button className="py-[14px] px-[20px] bg-[#44DA63] text-white rounded-[100px] font-[600] leading-[24px] tracking-[-0.4px] text-[16px] flex" onClick={onClickQuestion}>
          <img src={editIcon} className="self-center w-[16px] h-[16px] mr-[4px]"/>
          질문하기
        </button>
      </div>
        
      
      

    </div>
  )
}
GeoLocationHome.propTypes = {
  areaName: PropTypes.string,
  getCurrentPosition: PropTypes.func,
  hospitals: PropTypes.array,
}

function Home () {
  const { geolocation } = navigator;
  const [ loading, setLoading ] = useState(true);
  const [ lat, setLat ] = useState();
  const [ lon, setLon ] = useState();
  const [ areaName, setAreaName ] = useState("");
  const [ hospitals, setHospitals ] = useState([]);
  const [ searchParams ] = useSearchParams();
  const token = searchParams.get('token');
  window.sessionStorage.setItem('token', token);
  const client = axios.create({
    baseURL: "https://api.doodol.ai",
    headers: {
      "Authorization": "Bearer " + token
    }
  });
  const options = {
    enableHighAccuracy: true,
    // timeout: 1000 * 10,
    maximumAge: 1000 * 3600,
  }
  const handleSuccess = (pos) => {
    setLoading(true);
    setLat(pos.coords.latitude);
    setLon(pos.coords.longitude);
    client.get("/emergency-rooms", {
      params: {
        lat: pos.coords.latitude,
        lon: pos.coords.longitude,
      }
    }).then((res) => {
      console.log(res.data);
      setHospitals(res.data);
      setLoading(false);
    })
    client.get("/locations", {
      params: {
        lat: pos.coords.latitude,
        lon: pos.coords.longitude
      }
    }).then((res) => {
      // console.log(res.data);
      setAreaName(res.data.dong);
    })
  }
  const handleError = (err) => {
    console.log(err);
    setLoading(false);
    return null;
  }
  console.log(lat);
  console.log(lon);
  useEffect(() => {
    geolocation.getCurrentPosition(handleSuccess, handleError, options);
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage('Hi from Emergency Web');
    }
  }, []);
  return (
    <div>
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"></meta>
      {
        lat ? 
        <GeoLocationHome
          getCurrentPosition={() => {
            geolocation.getCurrentPosition(handleSuccess, handleError, options);
          }}
          areaName={areaName}
          hospitals={hospitals}
        /> : 
        <NoGeoLocationHome
          getCurrentPosition={() => {geolocation.getCurrentPosition(handleSuccess, handleError, options);}}
          loading={loading}
          setLoading={setLoading}
          />
      }
      
    </div>
  )
}

export default Home;