import React from 'react';
import MobileHome from "./MobileHome";


function DesktopHome () {
  return (
    <div className="w-full h-[100vh] bg-[#F9F9F9]">
      <div className="justify-center bg-[#FFFFFF] h-[100vh] text-center overflow-x-hidden scrollbar-hide max-w-[430px] m-auto">
        <MobileHome/>
      </div>
    </div>
  );
}

export default DesktopHome;