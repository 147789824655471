import React from 'react';
import Mobile from "./Mobile";


function MobileDesktopWrapper () {
  return (
    <div className="w-full h-full bg-[#F9F9F9]">
      <div className="justify-center text-center bg-white overflow-x-hidden scrollbar-hide max-w-[430px] m-auto">
        <Mobile/>
      </div>
    </div>
  );
}

export default MobileDesktopWrapper