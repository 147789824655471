import React, {useState, useEffect} from 'react';
import { Sheet } from 'react-modal-sheet';
// import React from 'react';

import axios from 'axios';
import searchIcon from '../../assets/hospital-recomm/black-search-icon.svg';
import { useSearchParams } from 'react-router-dom';
import chevronIcon from '../../assets/hospital-recomm/chevron.png';
import downChevronIcon from '../../assets/hospital-recomm/down-chevron.svg';
import upChevronIcon from '../../assets/hospital-recomm/up-chevron.svg';
import filterIcon from '../../assets/hospital-recomm/filter-icon.png';
import linkIcon from '../../assets/hospital-recomm/link.svg';
import xIcon from '../../assets/hospital-recomm/x.svg';
import Loader from "react-spinners/SyncLoader";

function Query () {
  const token = window.sessionStorage.getItem('token');
  const client = axios.create({
    baseURL: "https://api.doodol.ai",
    headers: {
      "Authorization": "Bearer " + token
    }
  });
  const [ filterOpen, setFilterOpen ] = useState(false);
  const [ area, setArea ] = useState(null);
  const [ loaded, setLoaded ] = useState(false);
  const [ hospitals, setHospitals ] = useState([]);
  const [ count, setCount ] = useState(0);
  const [ searchParams ] = useSearchParams();
  const [ areas, setAreas ] = useState([]);
  const query = searchParams.get('query');

  const pushBack = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({
        action: "BACK"
      }));
    }
  }

  const goSearchHome = () => {
    window.location.href = "/hospital-recomm?search=1";
  }

  const openBottomSheet = link => () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({
        action: "WEBVIEW",
        params: {
          uri: link ? link : "https://m.cafe.naver.com/ca-fe/web/cafes/14449164/articles/52674"
        }
      }));
    }
  }

  const toggleVisible = (idx) => () => {
    if (hospitals[idx].comments == undefined) {
      client.get(`/hospitals/${hospitals[idx].id}/reviews`, {
        params: {
          id: hospitals[idx].id,
          keyword: query,
        }
      }).then(res => {
        setHospitals([
          ...hospitals.slice(0, idx),
          {
            ...hospitals[idx],
            visible: hospitals[idx].visible ? false: true,
            comments: res.data,
          },
          ...hospitals.slice(idx+1, hospitals.length)
        ]);
      });
    } else {
      setHospitals([
        ...hospitals.slice(0, idx),
        {
          ...hospitals[idx],
          visible: hospitals[idx].visible ? false: true
        },
        ...hospitals.slice(idx+1, hospitals.length)
      ]);
    }
  }

  useEffect(() => {
    client.get("/hospitals", {
      params: {
        keyword: query,
      }
    }).then(res => {
      setHospitals(res.data);
      setCount(res.data.reduce((cv, item) => item.reviewCount + cv, 0));
      setLoaded(true);
    });

    client.get("/codes/si").then(res => {
      const data = res.data.map(d => d.name);
      data.sort();
      setAreas(data);
    })
  }, []);

  return (
    <>
    {/* FilterSheet */}
    <Sheet
      isOpen={filterOpen}
      detent='full-height'
      onClose={() => {}}
      snapPoints={[600, 400, 200, 0]}
      onSnap={(snapIdx) => {
        if (snapIdx > 0) {
          setFilterOpen(false);
        }
      }}
    >
      <Sheet.Container>
        <Sheet.Header>
          <div className="text-center text-[#505050] text-[20px] font-[600] leading-[28px] my-[14px]">
            지역을 설정해 주세요
          </div>
          <img src={xIcon} className="absolute right-[12px] top-[6px] mx-[8px] my-[8px]" onClick={() => {setFilterOpen(false)}}/>
          
        </Sheet.Header>
        <Sheet.Content>
          <div className="overflow-y-auto">
            {areas.map((area, idx) => {
              return (<div className="px-[20px] py-[11px] text-[#505050] text-[15px] leading-[22px]" key={`filter_area_${idx}`} onClick={() => {
                  setArea(area);
                  setCount(hospitals.reduce((cv, item) => {
                    return item.si.name == area ? item.reviewCount + cv : cv
                  }, 0));
                  setFilterOpen(false);
                }}>
                {area}
              </div>)
            })}
          </div>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>

    <div className="justify-center text-center overflow-x-hidden scrollbar-hide pretendard tracking-[-2.5%]">
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"></meta>
      <nav className="h-[44px] fixed w-full text-[#1F1F1F] bg-white">
        <div className="flex justify-between">
          <img src={chevronIcon} className="w-[24px] h-[24px] mx-[12px] my-[10px]" onClick={pushBack}/>
          <div className="font-[600] text-[16px] leading-[24px] my-[10px]">
            {query}
          </div>
          <img className="my-[12px] mx-[20px]" onClick={goSearchHome}
            src={searchIcon}
          />
        </div>
        <div className="flex justify-between text-[#333] text-[13px] leading-[18px] bg-white">
          <div className="mx-[20px] my-[14px]">
            총 {count}개
          </div>
          <div className="flex mx-[20px] my-[14px]" onClick={() => {setFilterOpen(prev => !prev)}}>
            지역설정
            <img src={filterIcon} className="w-[16px] h-[16px] ml-[4px] mt-[1px]"/>
          </div>
        </div>
      </nav>

      {!loaded ?
      <div>
        <Loader
          color="#44DA63"
          loading={!loaded}
          size={15}
          className="mt-[160px] py-[20px]"
        />
      </div> : 
      <div className="mt-[112px] text-left">
      {hospitals.map((hospital, idx) => {
        if (area != null && area != hospital.si.name) {
          return null;
        }
        return (<div key={`hospital_${idx}`} className="px-[20px]">
          <div className="flex w-full justify-between">
            <div className="text-[#1F1F1F] font-[600] text-[16px] leading-[24px] max-w-[75%]">
              {hospital.name}
            </div>
            <div className="bg-[#44DA63] text-white leading-[18px] font-[500] text-[12px] h-fit px-[6px] py-[2px] rounded-[4px] my-[1px]">
              후기 {hospital.reviewCount}개
            </div>
          </div>
          <div className="mt-[8px] text-[13px] leading-[18px] text-[#747474]">
            {hospital.si.name}
          </div>
          {
            hospital.visible?
            <div className="bg-[#F9F9F9] rounded-[8px] px-[16px] mt-[24px]">
              {hospital.comments.map((comment, j) => {
                console.log(comment);
                return (
                  <div key={`hospital_${idx}_comment_${j}`} className="">
                    <div className="flex justify-between">
                      <div className="text-[12px] leading-[18px] text-[#959595] mt-[24px]">
                        {comment.author + "OOO"} {comment.doctor ? "•" : null}
                        {comment.doctor ? <span className="text-[#8CD57C] font-[400]"> 진료 의사 {comment.doctor}</span> : null}
                      </div>
                      <div className="flex my-[20px] mx-[10px] text-[#505050] leading-[18px] text-[12px] mt-[24px] mb-[12px]" onClick={openBottomSheet(comment.originalUrl)}>
                        원문 보기 <img src={linkIcon} className="ml-[4px]"/>
                      </div>
                    </div>
                    <div className="text-[14px] leading-[20px]">
                      {comment.content.replaceAll('\\r', '').replaceAll('\\n', '')}
                    </div>
                    <div className="h-[1px] w-full bg-[#FFFFFF] mt-[24px]"></div>
                  </div>
                )
              })}
              <div className="bg-[#F9F9F9] text-center mt-[40px]">
                <div className="my-[20px] mx-[20px] text-center inline-flex text-[14px] text-[#747474] leading-[20px] rounded-[8px]" onClick={toggleVisible(idx)}>
                  병원 후기 닫기 <img src={upChevronIcon} className="ml-[4px]"/>
                </div>
              </div>
            </div> :
            <div className="bg-[#F9F9F9] text-center mt-[24px]" onClick={toggleVisible(idx)}>
              <div className="my-[14px] mx-[20px] text-center inline-flex text-[14px] text-[#747474] leading-[20px] rounded-[8px]">
                병원 후기 보기 <img src={downChevronIcon} className="ml-[4px]"/>
              </div>
            </div>
          }
          <div className="h-[1px] w-full my-[36px] bg-[#F4F4F4]"></div>
          <div/>
        </div>)
      })}
    </div>
      }
      
    </div>
    </>
  )
}


export default Query;