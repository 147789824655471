import React, {useState, useEffect} from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import {
  useParams
} from "react-router-dom";

const colorMap = {
  0: {
    bg: "rgba(64, 202, 20, 0.10)",
    text: "#3FCA13"
  },
  2: {
    bg: "rgba(64, 202, 20, 0.10)",
    text: "#3FCA13"
  },
  4: {
    bg: "rgba(217, 194, 0, 0.10)",
    text: "#D9C200"
  },
  6: {
    bg: "rgba(255, 184, 0, 0.10)",
    text: "#FFB900"
  },
  8: {
    bg: "rgba(251, 87, 94, 0.10)",
    text: "#FB575E"
  }
}


function Detail () {
  const token = window.sessionStorage.getItem('token');
  const client = axios.create({
    baseURL: "https://api.doodol.ai",
    headers: {
      "Authorization": "Bearer " + token
    }
  });

  const copy = () => {
    if (
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i)
    ) {
      toast.remove();
      toast.success("복사가 완료되었어요.", {
        position: 'bottom-center'
      });
    }
  }

  const { roomId } = useParams();

  const [ hospital, setHospital ] = useState({
    id: null,
    kind: null,
    name: null,
    address: null,
    phone: null,
    waitLevel: null,
    sickbed: {
      max: 0,
      leftOver: 0
    },
    workingHours: []
  });

  const [ messages, setMessages ] = useState([])

  useEffect(() => {
    client.get('/emergency-rooms/' + roomId).then(res => {
      console.log(res.data);
      setHospital(res.data);
    });
    client.get('/emergency-rooms/' + roomId + '/messages').then(res => {
      console.log(res.data);
      setMessages(res.data);
    });
  }, []);
    
  return (
    <div className="px-[24px] py-[24px] overflow-x-hidden scrollbar-hide">
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"></meta>
      <div className="border-[1px] rounded-[8px] outline-[#EAEAEA] px-[24px] py-[24px] text-[#333] tracking-[-0.4px] leading-[24px] font-[600] text-[16px]">
        <div className="">
          {hospital.name}
        </div>

        <div className="h-[14px]">

        </div>

        <div className="flex">
          <div className="rounded-[4px] px-[6px] py-[2px] tracking-[-0.3px] font-[500] leading-[18px] text-[12px] w-fit mr-[8px]" style={{
            backgroundColor: "#F9F9F9",
            color: "#959595"
            }}>
              {hospital.kind}
          </div>

          <div className="rounded-[4px] px-[6px] py-[2px] tracking-[-0.3px] font-[500] leading-[18px] text-[12px] w-fit" style={{
            backgroundColor: colorMap[2].bg,
            color: colorMap[2].text
            }}>
              진료 가능
          </div>
        </div>
      </div>

      <div className="h-[24px]"></div>

      <div className="text-[14px] font-[600] leading-[20px] tracking-[-0.35px] text-[#111]">
        진료 정보
      </div>
      <div className="h-[8px]"></div>
      <div className="py-[24px] rounded-[8px] bg-[#F9F9F9] flex justify-center">
        {/* <div className="text-center mr-[25px]">
          <div className="text-center text-[13px] text-[#747474] leading-[18px] tracking-[-0.325px]">접수 대기</div>
          <div className="h-[8px]"></div>
          <div className="text-[14px] font-[600] tracking-[-0.35px] leading-[20px]" style={{color: colorMap[hospital.waitLevel * 2].text}}> {hospital.waitLevel * 2 + ' ~ ' + (hospital.waitLevel * 2 + 2)}시간</div>
        </div>
        <div className="w-[1px] border-[0.5px] outline-[#D3D3D3]"></div> */}
        <div className="text-center mr-[37px]">
          <div className="text-[13px] text-[#747474] leading-[18px] tracking-[-0.325px]">잔여 병상수</div>
          <div className="h-[8px]"></div>
          {
            hospital.sickbed.leftOver != null ?
            <div className="text-[14px] font-[600] tracking-[-0.35px] leading-[20px]" style={{color: colorMap[Math.max(2 * (4 - parseInt((Math.max(hospital.sickbed.leftOver, 0)+1) / 2)), 2)].text}}> {hospital.sickbed.leftOver}자리</div> : null
          }
          
        </div>
        <div className="w-[1px] border-[0.5px] outline-[#D3D3D3]"></div>
        <div className="text-center ml-[37px]">
          <div className="text-[13px] text-[#747474] leading-[18px] tracking-[-0.325px]">총 병상수</div>
          <div className="h-[8px]"></div>
          {
            hospital.sickbed.max != null ?
            <div className="text-[14px] font-[600] tracking-[-0.35px] leading-[20px]" style={{color: "#505050"}}> {hospital.sickbed.max}자리</div> : null
          }
          
        </div>
      </div>

      {
        messages.length > 0 ?
        <div>
          <div className="h-[8px]"></div>
          <div className="pt-[16px] pb-[8px] px-[16px] rounded-[8px] bg-[#F9F9F9] text-left text-[#747474] text-[12px] font-[400] leading-[18p] tracking-[-0.3px]" style={{background: 'rgba(251, 87, 94, 0.05)'}}>
            <div className="text-[12px] text-[#FB575E] font-[600] leading-[18px] tracking-[-0.3px] mb-[8px]">실시간 응급실 알림</div>
            {
              messages.map((message, idx) => {
                return (
                  <div className="flex mb-[8px]" key={"message_" + idx}>
                    <div className="mr-[4px]">
                      •
                    </div>
                    <div>
                      {message}
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div> : null
      }


      <div className="text-[12px] leading-[18px] tracking-[-0.3px] text-[#959595] flex pt-[8px]">
        <div className="mx-[4px]">
          •
        </div>
        <div>
          해당 내용은 병원 사정에 의해 변동 될 수 있습니다. 정확한 진료를 위해
          병원에 전화 후 방문할 것을 권장합니다.
        </div>
      </div>
      <div className="h-[24px]"></div>
      <div className="text-[14px] font-[600] leading-[20px] tracking-[-0.35px] text-[#111] mb-[8px]">
        진료 시간
      </div>
      {
        hospital.workingHours.length > 0 ?
        <div className="bg-[#F9F9F9] pb-[24px]">
          <div className="py-[24px] rounded-[8px] px-[24px] [contain:content] flex">
            <div className="w-half text-[13px] leading-[18px] tracking-[-0.325px]">
              <div className="text-[#747474] mb-[4px]">주중</div>
              <div className="text-[#747474] font-[600]">{hospital.workingHours[0].startsAt.substr(0,5)} ~ {hospital.workingHours[0].endsAt.substr(0,5)}</div>
              <div className="h-[16px]"></div>
              <div className="text-[#FB575E] mb-[4px]">일요일</div>
              <div className="text-[#FB575E] font-[600]">{hospital.workingHours.length > 6 ? hospital.workingHours[6].startsAt.substr(0,5) + " ~ " + hospital.workingHours[6].endsAt.substr(0,5) : "-"}</div>
            </div>
            
            <div className="w-half text-[13px] leading-[18px] tracking-[-0.325px] ml-[93px]">
              <div className=" text-[#588AF0] mb-[4px]">토요일</div>
              <div className="text-[#588AF0] font-[600]">{hospital.workingHours.length > 5 ? hospital.workingHours[5].startsAt.substr(0,5) + " ~ " + hospital.workingHours[5].endsAt.substr(0,5) : "-"}</div>
              <div className="h-[16px]"></div>
              <div className="text-[#FB575E] mb-[4px]">공휴일</div>
              <div className="text-[#FB575E] font-[600]">{hospital.workingHours.length > 7 ? hospital.workingHours[7].startsAt.substr(0,5) + " ~ " + hospital.workingHours[7].endsAt.substr(0,5) : "-"}</div>
            </div>
          </div>
          {
            hospital.message ?
            <div className="mx-[24px] px-[10px] py-[8px] rounded-[4px] leading-[16px] bg-white tracking-[-0.3px] font-[600] text-[#747474] text-[12px] text-center">
              {hospital.message}
            </div> : null
          }
        </div> : null
      }

      <div className="h-[24px]"></div>
      <div className="text-[14px] font-[600] leading-[20px] tracking-[-0.35px] text-[#111] mb-[8px]">
        병원 연락처
      </div>
      <div className="py-[24px] rounded-[8px] bg-[#F9F9F9] px-[24px] [contain:content]">
        <div className="font-[600] text-[13px] leading-[18px] tracking-[-0.325px] text-[#747474] py-[4px] float-left max-w-[80%]">
          {hospital.phone}
        </div>
      </div>
      <div className="h-[24px]"></div>
      <div className="text-[14px] font-[600] leading-[20px] tracking-[-0.35px] text-[#111] mb-[8px]">
        병원 위치
      </div>
      <div className="py-[24px] rounded-[8px] bg-[#F9F9F9] px-[24px] [contain:content] flex items-center justify-between">
        <div className="font-[600] text-[13px] leading-[18px] tracking-[-0.325px] text-[#747474] py-[4px] float-left max-w-[205px]">
          {hospital.address}
        </div>
        <CopyToClipboard text={hospital.address} onCopy={copy}>
          <div className="text-[#00CEB3] text-[12px] leading-[18px] tracking-[-0.3px] rounded-[4px] px-[6px] py-[4px] float-right" style={{background: "rgba(0, 202, 179, 0.10)"}}>
            주소복사
          </div>
          
        </CopyToClipboard>
        
      </div>
      <Toaster
        toastOptions={{
          style: {
            marginBottom: "70px",
          }
        }}
      />
      <div className="h-[136px]"></div>
      
      
      
      <a href={`tel:${hospital.phone}`}>
        <div className="fixed bottom-[0px] left-0 px-[20px] py-[12px] w-full bg-[#FFFFFF]">
        <button className="w-full py-[14px] bg-[#44DA63] text-white rounded-[8px] font-[600] leading-[24px] tracking-[-0.4px] text-[16px]">
          진료 전화문의
        </button>
      </div>
      </a>
    </div>
  )
}

export default Detail;