import React, {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import './Home.css';
import { useDebounce } from 'use-debounce'
import locationIcon from "../../assets/hospital-recomm/location-icon.svg";
import axios from 'axios';
import searchIcon from '../../assets/hospital-recomm/search-icon.svg';
import circleSearch from '../../assets/hospital-recomm/circle-search-icon.svg';
import recommIcon from '../../assets/hospital-recomm/recomm-icon.png';
import { useSearchParams } from 'react-router-dom';
import chevronIcon from '../../assets/hospital-recomm/chevron.png';

const sample1 = [
  '약시', '소아정신과', '고열', '콧물', '내사시', '알러지', '발달지연', '외사시', '초고도원시',
  '척추측만증', '사시', '근시', '과잉치', '정형외과 문제', '결막염', '호흡기 문제',
  '소아 비염, 축농증', '소아안과', '상사시,복합사시,고도원시,고도근시,약시', '언어지연',
  '소아 아데노이드 비대', '경직', '난시, 원시', '손가락 골절 의심', '신경치료',
  '고도 원시, 난시, 약시, 사시', '유피종', '척추', '발달', '소아재활', '예방접종', '심장 질환',
  '소아 열', '발달 지연', '복통', '고관절 활액막염', '난시, 약시', '간혈관종', '재활치료',
  '안짱다리', '폐렴', '피부질환', '소아족저피부염', '천식', '중이염', '간헐외사시', '간헐적 외사시',
  '소아사시', '고관절 이형성증', '장중첩증', '소아응급', '소아 치과', '충치', '어지럼증', '팔골절',
  '열경기', '경련, 연축', '정형외과', '평발', '흉부 통증', '발목 골절', '고도원시', '자폐',
  '소아신경과', '화상', '열감기', '소아외과', '심장잡음', '난시', '후유장해 진단', '설사', '사경',
  '성조숙증', '간헐적외사시', '재활의학과', '소아 알레르기성 결막염', '뇌전증', '자폐스펙트럼', '부정맥',
  '요로감염'
];
const sample2 = [
  '서혜부탈장', '장염', '심장 문제', '아토피', '소아 낮병동', '알레르기접촉성피부염', '고관절탈구',
  '소아 정형외과', '염증', '척추측만', '대근육 발달 문제', '피검사', '배 통증', '복시',
  'BCG 예방접종', '복부초음파', '약시, 사시', '눈물고임', '재활', '소아정형외과', '간헐성 외사시',
  '입술 처짐', '물사마귀', '시력 저하', '매복치아', '틱', '열', '변비', '빈뇨', '상처',
  '야뇨증', '두드러기', '다리 통증', '속눈썹 찌름', '언어치료', '발달 장애', '편도 아데노이드',
  '팔꿈치 골절', '손가락 뼈 문제', '사경,사두증', '뇌 검사', '상사시', '시력저하', '기타 안질환',
  '고관절 문제', '소아 탈장', '심장 두근거림', '소아암', 'SMA', '다리 절뚝거림', '백질연하증',
  '독감', '무릎 통증', '원시', '간헐성 사시', '어깨인대파열', '발 통증', '소아응급실', '소아 질환',
  '산부인과 질환', '영유아검진', '비염', '근시, 난시', '유문협착증', '갑상선 기능저하증',
  '선천성 백내장', '고도근시', '소아 두통', '사두', '눈물길 막힘', '안과검사', '안검하수', '다래끼',
  '콩다래끼', '소아성대결절', '고도 원시', '기관지염, 폐렴', '탈장', '눈 질환', '소아천식'
]


function NormalHome (props) {
  const scrollRef = useRef(null);
  const [isDrag, setIsDrag] = useState(false);
  const [startX, setStartX] = useState();
  const [threshold, setThreshold] = useState(1);

  const throttle = (func, ms) => {
    let throttled = false;
    return (...args) => {
      if (!throttled) {
        throttled = true;
        setTimeout(() => {
          func(...args);
          throttled = false;
        }, ms);
      }
    };
  };

  const onDragStart = (e) => {
    e.preventDefault();
    setIsDrag(true);
    setStartX(e.pageX + scrollRef.current.scrollLeft);
  };

  const onDragEnd = () => {
    setIsDrag(false);
  };

  const onDragMove = (e) => {
    if (isDrag) {
      const { scrollWidth, clientWidth, scrollLeft } = scrollRef.current;
  
      scrollRef.current.scrollLeft = startX - e.pageX;
  
      if (scrollLeft === 0) {
        setStartX(e.pageX);
      } else if (scrollWidth <= clientWidth + scrollLeft) {
        setStartX(e.pageX + scrollLeft);
      }
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      scrollRef.current.scrollLeft += 1.0;
      if (scrollRef.current.scrollLeft > 2000 * threshold) {
        props.setKeywords1(prevArr => [...prevArr, ...sample1]);
        props.setKeywords2(prevArr => [...prevArr, ...sample2]);
        setThreshold(thr => thr + 1);
      }
    }, 50)
    return () => {
      clearInterval(interval)
    }
  }, [threshold]);

  const delay = 100;
  const onThrottleDragMove = throttle(onDragMove, delay);
  return (
    <div className="justify-center text-center overflow-x-hidden scrollbar-hide pretendard tracking-[-2.5%]">
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"></meta>
      <nav className="h-[44px]">
        <img src={chevronIcon} className="w-[24px] h-[24px] mx-[12px] my-[10px]" onClick={props.pushBack}/>
      </nav>
      <div className="text-[#44DA63] text-[24px] font-[600] leading-[34px] tracking-[-0.6px] mb-[12px] mt-[48px]">
        <img className="mb-[24px] m-auto" src={locationIcon} 
/>
        소아 진료 잘보는 병원&교수님 어디?
      </div>

      <div className="text-[12px] text-[#505050] leading-[18px] tracking-[-0.3px]">
        두돌 AI가 부모님들의 병원&의료진 추천 데이터를 수집했습니다.<br/>
        광고 없는 진짜 후기 보고, 소중한 우리 아기에게 맞는 병원을 찾아보세요!
      </div>

      <div className="h-[73px]">

      </div>

      <div className="text-[16px] font-[600] text-[#1F1F1F] leading-[24px] inline-flex">
        <img src={recommIcon} className="w-[26px] h-[26px] mr-[8px]"/>
        추천 키워드
      </div>

      <div className="h-[20px]"></div>

      <div className="scrollDiv overflow-x-scroll" ref={scrollRef}
        onMouseDown={onDragStart}
        onMouseMove={onThrottleDragMove}
        onMouseUp={onDragEnd}
        onMouseLeave={onDragEnd}
      >
        <div className="px-[20px] flex">
          {
          props.keywords1.map((keyword, idx) => {
            return (
              <div key={`keyword1_${idx}`} className="flex-none text-[#505050] px-[16px] py-[9px] text-[13px] leading-[18px] rounded-[18px] border-[1px] border-[#EAEAEA] mr-[8px]" onClick={props.onClickKeyword(keyword)}>
                {keyword}
              </div>
            )
          })
          }
        </div>
        <div className="flex mt-[8px] ml-[-20px]">
          {
          props.keywords2.map((keyword, idx) => {
            return (
              <div key={`keyword1_${idx}`} className="flex-none text-[#505050] px-[16px] py-[9px] text-[13px] leading-[18px] rounded-[18px] border-[1px] border-[#EAEAEA] mr-[8px]" onClick={props.onClickKeyword(keyword)}>
                {keyword}
              </div>
            )
          })
          }
        </div>
      </div>
      
      
      <div className="absolute bottom-[54px] w-full px-[20px] max-w-[430px]" onClick={props.toggleClick(true)}>
        <div className="border-[1px] border-[#44DA63] rounded-[10px] pl-[16px] pr-[16px] py-[13px] text-left flex justify-between">
          <input className="text-[15px] text-[#333] leading-[22px] tracking-[-0.375px] font-[400] min-w-[85%] focus:outline-none"
            placeholder="궁금한 질병을 검색해 보세요"
          />
          <img
            src={searchIcon}
          />
        </div>
      </div>
    </div>
  )
}

NormalHome.propTypes = {
  toggleClick: PropTypes.func,
  keywords1: PropTypes.array,
  keywords2: PropTypes.array,
  setKeywords1: PropTypes.func,
  setKeywords2: PropTypes.func,
  onClickKeyword: PropTypes.func,
  pushBack: PropTypes.func,
}

function ClickedHome (props) {
  const inputRef = useRef(null);
  const scrollRef = useRef(null);
  const [isDrag, setIsDrag] = useState(false);
  const [startX, setStartX] = useState();
  const [threshold, setThreshold] = useState(1);
  // const [cands, setCands] = useState(["1", "2", "3", "4", "5"]);
  const [cands, setCands] = useState([]);
  const client = axios.create({
    baseURL: "https://api.doodol.ai",
    headers: {
      "Authorization": "Bearer " + props.token
    }
  });
  const [query, setQuery] = React.useState(null)
  const [debouncedQuery] = useDebounce(query, 250)


  const throttle = (func, ms) => {
    let throttled = false;
    return (...args) => {
      if (!throttled) {
        throttled = true;
        setTimeout(() => {
          func(...args);
          throttled = false;
        }, ms);
      }
    };
  };

  const onDragStart = (e) => {
    e.preventDefault();
    setIsDrag(true);
    setStartX(e.pageX + scrollRef.current.scrollLeft);
  };

  const onDragEnd = () => {
    setIsDrag(false);
  };

  const onDragMove = (e) => {
    if (isDrag) {
      const { scrollWidth, clientWidth, scrollLeft } = scrollRef.current;
  
      scrollRef.current.scrollLeft = startX - e.pageX;
  
      if (scrollLeft === 0) {
        setStartX(e.pageX);
      } else if (scrollWidth <= clientWidth + scrollLeft) {
        setStartX(e.pageX + scrollLeft);
      }
    }
  };

  useEffect(() => {
    if (!debouncedQuery) {
      setCands([]);
      return;
    }

    client.get("/diseases", {
      params: {
        q: debouncedQuery
      }
    }).then(res => {
      setCands(res.data.map(item => item.keyword));
    })
  }, [debouncedQuery])

  useEffect(() => {
    const scrollContainer = scrollRef.current;
    if (!scrollContainer) return;

    const interval = setInterval(() => {
      scrollRef.current.scrollLeft += 1.0;
      if (scrollRef.current.scrollLeft > 2000 * threshold) {
        props.setKeywords1(prevArr => [...prevArr, ...sample1]);
        props.setKeywords2(prevArr => [...prevArr, ...sample2]);
        setThreshold(thr => thr + 1);
      }
    }, 33)
    return () => {
      clearInterval(interval)
    }
  }, [threshold, props]);

  const delay = 100;
  const onThrottleDragMove = throttle(onDragMove, delay);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <div className="justify-center text-center overflow-x-hidden scrollbar-hide pretendard tracking-[-2.5%] h-full">
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"></meta>
      <div className="w-full mt-[12px] max-w-[430px] px-[20px]">
        <div className="border-[1px] border-[#44DA63] rounded-[10px] text-left flex justify-between">
          <img className="mx-[12px] my-[13px] w-[24px] h-[24px]" onClick={props.pushBack}
            src={chevronIcon}
          />
          <input className="text-[15px] text-[#333] text-left leading-[22px] tracking-[-0.375px] font-[400] w-full focus:outline-none"
            ref={inputRef} onChange={(e) => setQuery(e.target.value)} onKeyDown={(e) => {
              if (e.key == 'Enter') {
                props.onClickKeyword(cands[0])();
              }
            }}
            placeholder="궁금한 질병을 검색해 보세요"
          />
          <img className="my-[15px] mx-[16px]" onClick={props.onClickKeyword(cands[0])}
            src={searchIcon}
          />
        </div>
      </div>

      {
        cands.length > 0 ? 
          <div className="mt-[14px] px-[20px]">
          {cands.map((cand, idx) => {return (
            <div key={`cand_${idx}`} className="pr-[16px] py-[10px] flex text-[16px] text-[#333] leading-[24px] overflow-x-hidden" onClick={props.onClickKeyword(cand)}>
              <img src={circleSearch} className="mr-[12px]"/>
              {cand}
            </div>
          )})}
          </div>
        : null
      }

      <div style={{display: cands.length > 0 ? "none" : "block"}}>
        <div className="text-[12px] text-[#959595] leading-[18px] tracking-[-0.3px] mt-[25px]">
          두돌 AI가 부모님들의 병원&의료진 추천 데이터를 수집했습니다.<br/>
          광고 없는 진짜 후기 보고, 소중한 우리 아기에게 맞는 병원을 찾아보세요!
        </div>

        <div className="h-[49px]">

        </div>

        <div className="text-[16px] font-[600] text-[#1F1F1F] leading-[24px] inline-flex text-left w-full px-[20px]">
          <img src={recommIcon} className="w-[26px] h-[26px] mr-[8px]"/>
          추천 키워드
        </div>

        <div className="h-[20px]"></div>

        <div className="scrollDiv overflow-x-scroll" ref={scrollRef}
          onMouseDown={onDragStart}
          onMouseMove={onThrottleDragMove}
          onMouseUp={onDragEnd}
          onMouseLeave={onDragEnd}
        >
          <div className="px-[20px] flex">
            {
            props.keywords1.map((keyword, idx) => {
              return (
                <div key={`keyword1_${idx}`} className="flex-none text-[#505050] px-[16px] py-[9px] text-[13px] leading-[18px] rounded-[18px] border-[1px] border-[#EAEAEA] mr-[8px]" onClick={props.onClickKeyword(keyword)}>
                  {keyword}
                </div>
              )
            })
            }
          </div>
          <div className="flex mt-[8px] ml-[-20px]">
            {
            props.keywords2.map((keyword, idx) => {
              return (
                <div key={`keyword1_${idx}`} className="flex-none text-[#505050] px-[16px] py-[9px] text-[13px] leading-[18px] rounded-[18px] border-[1px] border-[#EAEAEA] mr-[8px]" onClick={props.onClickKeyword(keyword)}>
                  {keyword}
                </div>
              )
            })
            }
          </div>
        </div>
      </div>
    </div>
  )
}

ClickedHome.propTypes = {
  toggleClick: PropTypes.func,
  keywords1: PropTypes.array,
  keywords2: PropTypes.array,
  setKeywords1: PropTypes.func,
  setKeywords2: PropTypes.func,
  onClickKeyword: PropTypes.func,
  pushBack: PropTypes.func,
  token: PropTypes.string,
}

function Home () {
  const [ searchParams ] = useSearchParams();
  let token = searchParams.get('token');
  if (!token) {
    token = window.sessionStorage.getItem('token');
  } else {
    window.sessionStorage.setItem('token', token);
  }
  const search = searchParams.get('search');

  const [ clicked, setClicked ] = useState(search ? true : false);
  const [ keywords1, setKeywords1 ] = useState([]);
  const [ keywords2, setKeywords2 ] = useState([]);

  const onClickKeyword = (keyword) => () => {
    console.log(keyword);
    if(!token) {
      alert("소아 진료 병원 & 교수님 추천은 두돌 앱 다운로드 이후 가능해요!");
      downloadApp();
    } else {
      if(keyword) {
        window.location.href = "/hospital-recomm/query?query=" + keyword;
      }
    }
  }
  const pushBack = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({
        action: "BACK"
      }));
    }
  }

  useEffect(() => {
    if (keywords1.length == 0 && keywords2.length == 0) {
      setKeywords1(sample1);
      setKeywords2(sample2);
    }
  }, []);

  const downloadIOS = () => {
    window.location = "https://apps.apple.com/us/app/%EB%91%90%EB%8F%8C-5%EC%B4%88-%EC%95%88%EC%97%90-%EB%B0%9B%EC%95%84%EB%B3%B4%EB%8A%94-%EC%86%8C%EC%95%84%EA%B3%BC-%EC%A0%84%EB%AC%B8%EC%9D%98-%EC%A1%B0%EC%96%B8/id6480494993";
  };
  
  const downloadAOS = () => {
    window.location = "https://play.google.com/store/apps/details?id=com.postalpine.doodol";
  };
  
  const downloadApp = () => {
    if (
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i)
    ) {
      downloadIOS();
    } else {
      downloadAOS();
    }
  };
  

  const toggleClick = (bool) => () => {
    if(!token) {
      alert("소아 진료 병원 & 교수님 추천은 두돌 앱 다운로드 이후 가능해요!");
      downloadApp();
    }
    setClicked(bool);
  }

  return (
    !clicked ?
    <NormalHome
      toggleClick={toggleClick}
      keywords1={keywords1}
      keywords2={keywords2}
      setKeywords1={setKeywords1}
      setKeywords2={setKeywords2}
      onClickKeyword={onClickKeyword}
      pushBack={pushBack}
    /> :
    <ClickedHome
      toggleClick={toggleClick}
      keywords1={keywords1}
      keywords2={keywords2}
      setKeywords1={setKeywords1}
      setKeywords2={setKeywords2}
      onClickKeyword={onClickKeyword}
      pushBack={pushBack}
      token={token}
    />
  )
}

export default Home;