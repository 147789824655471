import React from 'react';
import '../../App.css';
import { useMediaQuery } from 'react-responsive';

import DesktopHome from './DesktopHome';
import MobileHome from './MobileHome';

const MAX_MOBILE_SIZE = 430;
const MIN_DESKTOP_SIZE = 431;

function Home() {
  const isDesktop = useMediaQuery({
    query: `(min-width: ${MIN_DESKTOP_SIZE}px)`,
  });
  const isMobile = useMediaQuery({
    query: `(max-width: ${MAX_MOBILE_SIZE}px)`,
  });

  return (
    <div>
      {isDesktop && <DesktopHome/>}
      {isMobile && <MobileHome/>}
    </div>
  );
}

export default Home;