import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './App.css';
import { useMediaQuery } from 'react-responsive';

import MobileDesktopWrapper from './pages/MobileDesktopWrapper';
import Mobile from './pages/Mobile';
import Privacy from './pages/Privacy';
import TOS from './pages/TOS';
import LocationTOS from './pages/LocationTOS';
import Home from './pages/emergency/Home';
import Detail from './pages/emergency/Detail';
import HospitalHome from './pages/hospital-recomm/Home';
import Query from './pages/hospital-recomm/Query';

const MAX_MOBILE_SIZE = 430;
const MIN_DESKTOP_SIZE = 431;

function Main() {
  const isDesktop = useMediaQuery({
    query: `(min-width: ${MIN_DESKTOP_SIZE}px)`,
  });
  const isMobile = useMediaQuery({
    query: `(max-width: ${MAX_MOBILE_SIZE}px)`,
  });

  return (
    <div>
      {isDesktop && <MobileDesktopWrapper/>}
      {isMobile && <Mobile/>}
    </div>
  );
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
  },
  {
    path: "/policy/ko/privacy",
    element: <Privacy />,
  },
  {
    path: "/policy/ko/tos",
    element: <TOS />,
  },
  {
    path: "/policy/ko/locationtos",
    element: <LocationTOS />
  },
  {
    path: "/emergency",
    element: <Home />,
  },
  {
    path: "/emergency/:roomId",
    element: <Detail />,
  },
  {
    path: "/hospital-recomm",
    element: <HospitalHome/>
  },
  {
    path: "/hospital-recomm/query",
    element: <Query/>
  }
]);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
